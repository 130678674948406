.location-change {
    display: inline-block;
    margin: 20px auto;
}

.location-field {
    border-radius: 20px;
    background: $blue1;
    padding: 30px;
    margin-bottom: 30px;
    font-size: 18px;

    cursor: pointer;

    .location-name {
        margin-left: 8px;
        color: $gray5;
        font-weight: bold;
        margin-bottom: 4px;
    }

    @import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap");
    .location-address {
        margin-left: 8px;
        margin-bottom: 0px;
        font-size: 16px;
        font-family: "Noto Sans JP", sans-serif;
        color: $gray4;
    }

    .location-open {
        margin-top: 16px;
        margin-left: 8px;
        color: $gray5;
        font-weight: bold;
    }

    .location-slots {
        .row > div {
            margin-top: 20px;
        }

        .row {
            margin: 0 10px 0 9px;
        }

        .location-slot,
        .location-slot-more {
            width: calc(100% + 7px);
            padding: 15px;
            border-radius: 10px;
            font-weight: bold;
            text-align: center;
            background: $white;
            color: $gray4;
            border: 2px solid $blue3;
        }

        .location-slot:hover {
            background: $blue4;
            border: 2px solid $blue4;
            color: $white;
        }

        .selected-location-slot,
        .location-slot:active {
            background: $blue4;
            border: 2px solid $blue4;
            color: $white;
        }
    }

    .point {
        background: url("../../components/fields/locationField/img/point.png")
            no-repeat center top;
        width: 100px;
        height: 47px;
        padding-top: 55px;
        font-size: 20px;
        text-align: center;
        font-weight: bold;
    }

    .location-information {
        margin: 20px 0 0 16px;
        font-weight: bold;
    }
}

.smaller-search-box {
    .location-change {
        div {
            font-size: 14px;
            padding: 5px 11px;
            margin-top: 8px;
            input {
                padding: 6px 0 0 5px;
            }
            label {
                top: 2px;
                font-size: 12px;
            }
            div {
                padding: 0px;
            }
        }
    }
}

// iPad - portrait
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    .location-field {
        .location-slot,
        .location-slot-more {
            width: auto;
            padding: 10px;
            font-size: 12px;
        }
        .location-slots {
            .col-4 {
                padding: 0 8px 0 8px;
            }
        }
    }
}

@media screen and (max-width: 1020px) {
    .location-field {
        .location-slot,
        .location-slot-more {
            width: auto;
            padding: 10px;
            font-size: 14px;
        }
        .location-slots {
            .col-4 {
                padding: 0 8px 0 8px;
            }
        }
    }
}

@media screen and (max-width: 840px) {
    .location-field {
        .location-slots {
            .location-slot,
            .location-slot-more {
                font-size: 12px;
            }
        }
        .location-slots {
            .col-4 {
                padding: 0 6px 0 6px;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .location-field {
        .location-slots {
            .location-slot,
            .location-slot-more {
                font-size: 18px;
            }
        }
        .location-slots {
            .col-4 {
                padding: 0 14px 0 14px;
            }
        }
    }
}

@media screen and (max-width: 576px) {
    .location-field {
        .location-slots {
            .location-slot,
            .location-slot-more {
                font-size: 12px;
            }
        }
        .location-slots {
            .col-4 {
                padding: 0 8px 0 8px;
            }
        }
    }
}

@media screen and (max-width: 410px) {
    .location-field {
        .location-slots {
            .location-slot,
            .location-slot-more {
                padding: 10px 5px;
                font-size: 10px;
            }
        }
        .location-slots {
            .col-4 {
                padding: 0 4px 0 4px;
            }
        }
    }
}
