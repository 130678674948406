.settingsButton,
.langButton,
.connectionButton {
    display: flex;
    flex-direction: row;
    height: 46px;
    width: 46px;
    align-items: center;
    position: fixed;
    bottom: 74px;
    right: 20px;
    border: 1px solid white;
    border-radius: 4px;
    padding: 7px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1);

    .langSelect {
        cursor: pointer;
        font-size: 15px;
        position: fixed;
        color: whitesmoke;
        line-height: 2;
        right: 78px;
        bottom: 74px;
        background-color: rgba(0, 0, 0, 0.1);
        border: 1px solid white;
        border-radius: 4px;
        padding: 10px;
    }
    .appSettings {
        cursor: pointer;
        font-size: 15px;
        position: fixed;
        color: whitesmoke;
        line-height: 2;
        right: 78px;
        bottom: 14px;
        background-color: rgba(0, 0, 0, 0.1);
        border: 1px solid white;
        border-radius: 4px;
        padding: 10px;
    }
    .logout-button {
        color: white;
        border: none;
    }
    .check-connection {
        position: absolute;
        top: 0;
        right: 57px;
        background-color: rgba(0, 0, 0, 0.1);
        padding: 10px;
        border-radius: 4px;
        border: 1px solid white;
        text-align: center;
        color: whitesmoke;
        fill: rgba(214, 208, 208, 0.897);
        .cmeter--sleep {
            .cmeter__iconWrapper {
                opacity: 0.5;
                fill: whitesmoke;
            }
        }
        .cmeter__iconWrapper {
            height: 50px;
            width: 50px;
            opacity: 0.7;
            border: 1px solid white;
        }
        .cmeter__dots {
            width: 40px;
            margin: 0 2px;
        }
        .start {
            margin-top: 10px;
        }
    }

    .langSelect :disabled {
        color: rgb(238, 238, 238);
        font-weight: bold;
    }
    .settingsIcon,
    .language,
    .connectionIcon {
        width: 30px;
        height: 30px;
    }
}

.settingsButton {
    bottom: 16px;
    right: 20px;
}
.connectionButton {
    top: 16px;
    right: 20px;
}

@media only screen and (max-width: 766px) {
    .settingsButton,
    .langButton,
    .connectionButton {
        height: 36px;
        width: 36px;
        bottom: 60px;
        right: 15px;
        padding: 5px;

        .check-connection {
            right: 42px;
        }

        .langSelect {
            font-size: 15px;
            line-height: 1.5;
            right: 59px;
            bottom: 16px;
            padding: 6px;
        }
        .appSettings {
            font-size: 15px;
            line-height: 1.5;
            right: 59px;
            padding: 6px;
        }
        .settingsIcon,
        .language,
        .connectionIcon {
            width: 24px;
            height: 24px;
        }
    }
    .settingsButton {
        bottom: 15px;
    }
    .connectionButton {
        top: 15px;
    }
}

@media only screen and (max-width: 550px) {
    .settingsButton,
    .langButton,
    .connectionButton {
        height: 26px;
        width: 26px;
        bottom: 42px;
        right: 10px;
        padding: 3px;

        .langSelect,
        .appSettings {
            font-size: 14px;
            line-height: 1.2;
            right: 42px;
            bottom: 10px;
            padding: 4px;
        }
        .settingsIcon,
        .language,
        .connectionIcon {
            width: 18px;
            height: 18px;
        }
    }
    .settingsButton {
        bottom: 10px;
        right: 10px;
    }
    .connectionButton {
        top: 10px;
        right: 10px;
    }
}
