.text-field,
.postal-code-field,
.select-field,
.date-field {
    background-color: $blue1;
    width: 100%;
    border-radius: 20px;
    position: relative;

    .MuiFormHelperText-root {
        position: absolute;
        top: 63%;
        left: 9%;
    }
}

.container {
    padding-top: 18px;
}

.container.lookup {
    position: relative;
    margin-top: -15px !important;
    margin-bottom: -15px !important;
    max-width: none;
    height: 100vh;
    width: 100vw;
    margin: auto;
}

.form-lookup {
    max-width: 1000px;
    margin: auto;
    padding-bottom: 60px;
}

.form-container {
    margin: 22px auto;
    border-radius: 20px;
    position: relative;
    > .row {
        min-height: 439px;
        padding-bottom: 20px;
        margin: auto;
    }

    .head-info {
        padding: 24px 0 14px 37px;
    }

    .text-field,
    .postal-code-field,
    .select-field,
    .date-field {
        margin: 8px 0px !important;
        height: 64px;
    }

    .MuiTextField-root,
    div:not(.no-title) > .MuiFormControl-root {
        &.select-field {
            .MuiInput-root {
                margin-top: 22px;
                margin-left: 10px;
            }
        }

        .MuiFormLabel-root {
            left: 20px;
            top: 10px;
            font-size: 17px;

            &.MuiInputLabel-formControl {
                transform: translate(0, 12px) scale(1);
            }

            &.MuiInputLabel-shrink {
                transform: translate(0, 1.5px) scale(0.85);
            }
        }
        input {
            padding: 12px 0 7px 20px;
        }
    }
    .informations-background {
        border-radius: 25px;
        background: #e8e9f1;
        padding: 20px 40px;
    }

    .MuiSelect-icon {
        top: calc(50% - 10px) !important;
        right: 10px;
    }

    .checked-in {
        color: #fff;
        font-size: 30px;
        text-align: center;
        display: flex;
        width: 100%;
        height: 100%;

        > div {
            width: 100%;
            height: 100%;
        }
    }
}

.head-info-lookup {
    margin: 6% auto 8% auto;
}

.form-row {
    display: flex;
    margin: 0;
}

.text-field,
.postal-code-field,
.select-field,
.info-field {
    background-color: $blue1;
    z-index: 0;
    width: 100%;
    border-radius: 20px;
}

.select-field:focus {
    background-color: unset;
}

.info-field {
    padding: 26px;
    height: unset;
}

.info-field .content {
    padding: 0;
    margin-bottom: 0;
}

.checkbox-field {
    margin: 4px 17px !important;
}

.checkbox-field .MuiSvgIcon-root {
    font-size: 56px;
}

.checkbox-field .MuiSvgIcon-root path {
    color: $blue1;
}
.checkbox-container {
    background-color: $blue1;
    border-radius: 20px;
    margin-top: 10px;
}

.checkbox-container .control-switch {
    background-color: $blue1;
    padding: 5px 10px;
    border-radius: 20px;
    display: inline-block;
    float: right;
    margin-top: 15px;
}

.checkbox-container .control-switch button {
    border-radius: 20px;
    padding: 2px 10px;
    border: none !important;
    outline: none !important;
}

.checkbox-container .control-switch button.active {
    box-shadow: inset 7px 4px 6px 0px #dddddd,
        inset 3px 3px 1px 0px rgba(189, 193, 209, 0.8);
}

.checkbox-container .description-container {
    padding: 0 40px 10px 40px;
}

.checkbox-container.description-hidden {
    background-color: unset;
}

.checkbox-container.description-hidden .description-container {
    display: none;
}

.checkbox-container .description-container .description-text {
    white-space: pre-line !important;
}

.form-label {
    color: $gray5;
    font-size: 28px;
    margin-bottom: 15px;
}

.form-label-or {
    color: $gray4;
    margin: 70px 0 0 0;
    font-size: 48px;
}

.locate-container {
    display: flex;
    flex-wrap: wrap;
    .btn-locate-me {
        background: $blue3;
        border: 2px solid $white;
        border-radius: 0;
        padding: 11px;
        display: inline-block;
        margin-right: 10px;
        margin-top: 10px;
    }
    .btn-go {
        background: $red-dark;
        border-color: $red-dark;
        border-radius: 0;
        padding: 11px;
        display: inline-block;
        margin-right: 10px;
        margin-top: 10px;
    }

    .text-field,
    .select-field {
        border-radius: 0;
        display: inline-flex;
        width: 250px;
        margin-right: 10px;
        margin-top: 10px;
    }

    .select-field {
        margin-right: 0;
        margin-top: 10px;
    }

    .select-service-type {
        display: flex;
    }
}

.service-title {
    color: $white;
    font-size: 35px;
}

.service-info {
    color: $white;
    font-size: 16px;
    margin: 48px 0 0 0;
}

.service-description {
    color: $blue1;
    font-size: 14px;
    opacity: 0.8;
    margin: 0 0 16px 0;
}

.control-button {
    position: absolute;
    top: 50%;
    margin-top: -33px;
    width: 65px;
    height: 65px;
    box-shadow: -7.071px -7.071px 8px 0px rgba(216, 224, 228, 0.3),
        3.993px 3.009px 8px 0px rgba(189, 193, 209, 0.3),
        inset -0.707px -0.707px 8px 0px rgba(245, 246, 250, 0.3),
        inset 0.707px 0.707px 8px 0px rgba(233, 234, 242, 0.3);
    border-radius: 50%;
    background: #edeef3;
    text-align: center;
    line-height: 65px;
    cursor: pointer;
    z-index: 900;
    &.control-left {
        left: 20px;
    }
    &.control-right {
        right: 20px;
    }
    &.control-disabled {
        box-shadow: none;
        opacity: 0.6;
        cursor: not-allowed;
    }
}

.img-covid {
    margin: 28px 0 0 auto;
    max-height: 340px;
    max-width: 616px;
}

.logo-lookup {
    position: fixed;
    left: 30px;
    bottom: 30px;
    height: 39px;
    width: 166px;
    background-size: 166px 39px;
    background-image: url("../../components/forms/kiosk/lookupForm/img/logo.png");
}

.head-title-lookup {
    font-size: 80px;
    line-height: 80%;
    letter-spacing: 8px;
    color: $lookup;
    text-align: center;
}

.subtitle-lookup {
    font-size: 45px;
    letter-spacing: 4px;
    color: $lookup;
    text-align: center;
    font-style: italic;
}

// Workflow app variant gradient background
.main-container {
    &.morning {
        .app-form-background {
            background: rgb(109, 133, 252);
            background: $workflowBackgroundKioskMorning;
        }
    }
}
.main-container {
    &.afternoon {
        .app-form-background {
            background: rgb(66, 161, 254);
            background: $workflowBackgroundKioskAfternoon;
        }
    }
}
.main-container {
    &.evening {
        .app-form-background {
            background: rgb(255, 254, 254);
            background: $workflowBackgroundKioskEvening;
        }
    }
}

// iPad - portrait
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    .content-center {
        padding-top: 0px;
    }
}

// iPad - landscape
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    .control-button {
        &.control-left {
            left: 35px;
        }
        &.control-right {
            right: 35px;
        }
    }
}

@media screen and (max-width: 992px) {
    .control-button {
        width: 35px;
        height: 35px;
        line-height: 35px;

        img {
            width: 18px;
            height: 15px;
        }

        &.control-left {
            left: 2px;
        }
        &.control-right {
            right: 1px;
        }
    }
}

@media screen and (max-width: 767px) {
    .MuiGrid-root {
        .MuiPaper-root {
            height: 36px;
        }
    }
    .head-title-lookup {
        font-size: 60px;
        letter-spacing: 6px;
    }
    .subtitle-lookup {
        font-size: 30px;
        letter-spacing: 3px;
    }
    .container.lookup {
        height: 100%;
    }
    .logo-lookup {
        left: 10px;
        bottom: -30px;
        position: relative;
    }
    .checkboxIcon {
        height: 48px;
        width: 48px;
    }
    .form-container {
        > .row {
            padding: 10px 20px;
            margin: auto;
        }
    }
    .locate-container {
        text-align: left;
    }
    .service-title {
        font-size: 25px;
    }
}
@media screen and (max-width: 420px) {
    .head-title-lookup {
        font-size: 50px;
        line-height: 120%;
        letter-spacing: 5px;
        padding-bottom: 20px;
    }
    .subtitle-lookup {
        font-size: 25px;
        letter-spacing: 3px;
        padding-bottom: 20px;
        span {
            display: block;
            margin: auto;
            width: 200px;
        }
    }
}

@media screen and (max-width: 576px) {
    .info-field {
        padding: 20px;
    }
    .checkboxIcon {
        height: 40px;
        width: 40px;
    }
    .checkbox-container .description-container {
        padding: 20px;
    }
    .checkbox-container .control-switch {
        padding: 0px;
        margin: 16px;
        margin-left: 0;
    }
    .form-container {
        > .row {
            padding: 16px;
            margin: auto;
        }
    }
}

@media screen and (max-width: 450px) {
    .checkboxIcon {
        height: 30px;
        width: 30px;
    }
    .checkbox-container .control-switch {
        margin: 16px 16px 0 0;
        font-size: 13px;
    }
    .form-container {
        > .row {
            padding: 0;
            margin: auto;
        }
    }
    .description-text {
        font-size: 14px;
    }
    .checkbox-field {
        margin-left: 0 !important;
        padding-right: 0 !important;
    }
    .form-label {
        font-size: 22px;
    }
    .control-switch {
        button {
            margin-right: 20px !important;
        }
    }
    .MuiTypography-root {
        font-size: 14px !important;
    }
}

@media screen and (max-width: 370px) {
    .checkboxIcon {
        height: 24px;
        width: 24px;
    }
    .checkbox-container .control-switch {
        margin: 16px 16px 0 0;
        font-size: 12px;
    }
    .info-field {
        padding: 30px;
    }
    .description-text {
        padding: 10px;
        font-size: 12px;
    }
    .form-row {
        padding: 0 5px 0 5px;
    }
    .MuiTypography-root {
        font-size: 13px !important;
    }
}

@media screen and (max-height: 620px) {
    .container.lookup {
        height: 100%;
    }
    .logo-lookup {
        bottom: -20px;
        position: relative;
    }
}

@media all and (orientation: landscape) {
    .main-container {
        &.morning {
            .lookup-background {
                background-image: url("../../components/forms/kiosk/lookupForm/img/morning-landscape.png");
            }
        }
    }
    .main-container {
        &.afternoon {
            .lookup-background {
                background-image: url("../../components/forms/kiosk/lookupForm/img/afternoon-landscape.png");
            }
        }
    }
    .main-container {
        &.evening {
            .lookup-background {
                background-image: url("../../components/forms/kiosk/lookupForm/img/evening-landscape.png");
            }
        }
    }
}

@media all and (orientation: portrait) {
    .main-container {
        &.morning {
            .lookup-background {
                background-image: url("../../components/forms/kiosk/lookupForm/img/morning-vertical.png");
            }
        }
    }
    .main-container {
        &.afternoon {
            .lookup-background {
                background-image: url("../../components/forms/kiosk/lookupForm/img/afternoon-vertical.png");
            }
        }
    }
    .main-container {
        &.evening {
            .lookup-background {
                background-image: url("../../components/forms/kiosk/lookupForm/img/evening-vertical.png");
            }
        }
    }
}
