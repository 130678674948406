$gray1: #dddddd;
$gray2: #cccccc;
$gray3: #999999;
$gray4: #666666;
$gray5: #333333;

$blue1: #f2f2f4;
$blue2: #d0d8da;
$blue3: #aabec6;
$blue4: #2ebfe5;
$blue5: #007c92;
$blue6: #0a425d;

$blue4gradient: linear-gradient(
    90deg,
    rgba(0, 175, 222, 1) 0%,
    rgba(0, 204, 225, 1) 100%
);
$workflowBackgroundKioskMorning: linear-gradient(
    165deg,
    rgb(109, 133, 252) 0%,
    rgb(169, 164, 232) 40%,
    rgb(231, 194, 211) 80%,
    rgb(232, 198, 213) 93%,
    rgb(143, 153, 251) 100%
);
$workflowBackgroundKioskAfternoon: linear-gradient(
    to bottom,
    rgb(66, 161, 254) 0%,
    rgb(168, 209, 245) 40%,
    rgb(240, 227, 214) 67%,
    rgb(120, 184, 246) 82%,
    rgb(100, 175, 248) 100%
);

$workflowBackgroundKioskEvening: linear-gradient(
    to bottom,
    rgba(255, 254, 254, 0.96) 0%,
    rgba(237, 189, 189, 0.96) 66%,
    rgba(126, 125, 126, 0.96) 78%,
    rgba(142, 176, 210, 0.96) 97%
);

$red-light: #a22038;
$red: #8c1515;
$red-dark: #8d1517;

$pink: #aa206e;

$accent1: #c7d1c6;
$accent2: #80982a;
$accent3: #556222;
$accent4: #b96d12;
$accent5: #53284f;
$accent6: #5e3032;

$lookup: #4660c1;

$white: #ffffff;
$black: #000000;
