.modal-content {
    margin: auto;
    border-radius: 15px;
    background-color: white;
    width: 340px;
    .modal-header {
        margin-top: 15px;
        border: none;
        .modal-img {
            height: 172px;
            width: 273px;
            margin: auto;
            background-image: url("../containers/workflowKiosk/img/time-out.png");
        }
    }
    .modal-body {
        border: none;
        h2,
        h4 {
            text-align: center;
            color: gray;
            font-weight: bold;
        }
    }
    .modal-footer {
        border: none;
        .modal-btn {
            font-size: 24px;
            border: none;
            margin: auto;
            width: 240px;
            background-color: $blue4;
            border-radius: 12px;
            margin-bottom: 15px;
        }
    }
}

.workflow-container {
    background: $blue4gradient;
    height: 477px;
    padding-top: 15px;
}

.workflow-container.app {
    position: relative;
    background: $blue4gradient; //Fallback
    height: 100%;
    padding-bottom: 15px;
    min-height: 100vh;
}

.form-container {
    background: #cdd7dc;
    width: 1125px;
    border-radius: 10px;
    margin: 0 auto;
}

.i-have-no-id-field {
    .image {
        background-image: url("../components/fields/iHaveNoIdField/img/no_id.png");
        background-repeat: no-repeat;
        height: 205px;
        width: 205px;
    }

    .option-label {
        font-size: 20px;
        font-weight: bold;
        color: $lookup;
        text-align: center;
        margin-top: 20px;
    }
}

.scan-your-id-field {
    .image {
        background-image: url("../components/fields/blinkId/img/id.png");
        background-repeat: no-repeat;
        height: 205px;
        width: 205px;
        cursor: pointer;
    }
    .option-label {
        font-size: 20px;
        font-weight: bold;
        color: $lookup;
        text-align: center;
        margin-top: 20px;
    }
}

.scan-your-code-field {
    .button {
        background-image: url("../components/fields/qrScanner/img/button.png");
        background-repeat: no-repeat;
        height: 205px;
        width: 205px;
        background-size: 100%;
        cursor: pointer;
    }
    .option-label {
        font-size: 20px;
        font-weight: bold;
        color: $lookup;
        text-align: center;
        margin-top: 20px;
    }
}

.info-field {
    .content {
        padding: 10px;
    }
}

@media only screen and (max-width: 1200px) {
    .form-container {
        width: 100%;
    }
}
