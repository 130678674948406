.login-component {
    background-image: url("../components/login/img/login-vertical.png");
    background-size: cover;
    background-position: left bottom;
    height: 100%;
    min-height: 100vh;

    /* Chrome, Firefox, Opera, Safari 10.1+ */
    ::placeholder {
        color: white;
        opacity: 1; /* Firefox */
    }
    /* Internet Explorer 10-11 */
    :-ms-input-placeholder {
        color: white;
    }
    /* Microsoft Edge */
    ::-ms-input-placeholder {
        color: white;
    }

    .login-wrapper,
    .location-wrapper {
        margin: auto;
        width: 400px;
    }

    .login-img-wrapper {
        margin: 15% auto;

        img {
            width: 35vh;
        }
    }
    .error-login {
        background-color: rgba(27, 25, 25, 0.308);
        padding: 8px;
        padding-bottom: 0px;
        border-radius: 4px;
        color: white;
        text-align: center;
    }
    .header {
        width: 100%;
        font-family: "Noto Sans JP", sans-serif;
        text-align: center;
        font-size: 80px;
        font-weight: bold;
        color: white;
        padding-bottom: 50px;
    }

    .login-control {
        margin: 8px auto;
        width: 400px;
        color: white;
        margin-top: 15px;
        .error-msg {
            position: absolute;
            color: red;
            font-size: 13px;
            font-weight: bold;
            margin-left: 5px;
        }
    }
    input {
        width: 100%;
        padding-left: 32px;
        color: white;
        background-color: transparent;
        border: none;
        border-bottom: 2px solid white;
        outline: none;
    }
    .input-on-error {
        border-bottom: 2px solid red;
    }
    .e-mail-icon,
    .padlock-icon {
        position: relative;
        left: 8px;
        top: 22px;
        margin: 0;
        width: 14px;
        height: 15px;
    }
    .padlock-icon-error,
    .e-mail-icon-error {
        position: relative;
        left: 8px;
        top: 22px;
        margin: 0;
        width: 14px;
        height: 15px;
        color: red;
    }
    .e-mail-icon {
        top: 24px;
    }

    .logout-btn,
    .login-btn {
        color: white !important;
        margin-top: 30px;
        padding: 8px;
        background-color: $pink;
        border: 1px solid white;
    }

    .create-account-btn {
        padding: 8px;
    }
    .create-account-btn:hover {
        padding: 8px;
        background-color: rgba($color: #ffffff, $alpha: 0.8);
        color: gray;
    }
    .forgot-password {
        margin-bottom: 100px;
    }

    .forgot-password:hover,
    .login-btn:hover {
        color: white;
    }

    .location-select {
        margin: auto;

        .location-container {
            height: 390px;
            overflow: auto;
            border-radius: 6px;
            background: #fff;

            .location-box {
                padding: 5px 5px 5px 10px;
                font-size: 14px;
                margin: 0;
                border-bottom: 1px solid $gray2;
                cursor: pointer;
                p {
                    margin: 0;
                }
            }
        }
        svg {
            color: white;
            margin: 4px 0 0 366px;
            width: 40px;
            height: 40px;
            padding: 10px;
            cursor: pointer;
        }
        input {
            padding-left: 4px;
            width: 400px;
            margin: 8px 0;
            height: 30px;
        }
        .search {
            position: relative;
            > svg {
                position: relative;
                top: 40px;
            }
        }
        .logout-btn {
            margin: 50px 95px;
            width: 400px;
        }
    }
}

/* For general iPad layouts */
@media only screen and (device-width: 768px) {
    .login-component {
        .padlock-icon {
            top: 24px;
        }
        .e-mail-icon {
            top: 26px;
        }
    }
}

@media only screen and (max-width: 766px) {
    .login-component {
        .header {
            font-size: 60px;
        }
    }
}

@media only screen and (max-width: 450px) {
    .login-component {
        .header {
            font-size: 40px;
        }
        .login-control {
            width: 300px;
        }
        .location-select {
            svg {
                margin: 0 0 0 318px;
                padding-top: 0;
            }
            .location-container {
                width: 300px;
                height: 320px;
                display: block;
                margin: 0 auto;
            }
            .login-control {
                width: 300px;
            }
            .search {
                > input {
                    width: 300px;
                    display: block;
                    margin: 0 auto;
                    margin-bottom: 8px;
                }
            }
            .logout-btn {
                display: block;
                margin: 20px auto;
                width: 300px;
            }
        }
    }
}

@media only screen and (max-width: 340px) {
    .login-component {
        .header {
            font-size: 30px;
        }

        .login-control {
            width: 200px;
        }

        .location-select {
            svg {
                margin: 0 0 0 268px;
            }
            .location-container {
                width: 200px;
            }
            .logout-btn {
                width: 200px;
                p {
                    font-size: 10px;
                }
            }
            .search {
                > input {
                    width: 200px;
                }
            }
        }
    }
}

@media only screen and (min-device-width: 560px) and (max-device-width: 768px) and (orientation: landscape) {
    .login-component {
        .login-img-wrapper {
            margin: 10% auto;
            img {
                width: 45vw;
            }
        }
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    .login-component {
        .login-img-wrapper {
            margin: 10% auto;
            img {
                width: 35vw;
            }
        }
    }
}
