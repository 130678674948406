.timeSlotsContainer {
    margin: 0 80px 0 80px;

    .timeSlotsHeader {
        margin: 0 0 0 41px;
    }

    .timeSlots {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .moreLessButtonContainer {
        display: flex;
        justify-content: flex-end;
        margin: 0 35px 0 0;
    }

    .moreLessButton {
        cursor: pointer;
    }

    // Iphone 6, 7, 8 and X - portrait
    @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: portrait) {
        .timeSlotsHeader {
            font-size: 13px;
            text-align: center;
            margin: 0;
        }

        .moreLessButtonContainer {
            justify-content: center;
            margin-right: 0;
        }

        .moreLessButton {
            font-size: 13px;
        }
    }

    // Iphone X - landscape
    @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) {
        .timeSlotsHeader {
            margin: 0 0 0 20px;
        }
    }

    // Iphone 6, 7, 8 - landscape
    @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: landscape) {
        .timeSlotsHeader {
            margin: 0 0 0 60px;
        }

        .moreLessButtonContainer {
            margin: 14px 60px 0 0;
        }
    }

    // iPad - portrait
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
        .timeSlotsHeader {
            font-size: 16px;
            text-align: left;
            margin: 0 0 0 75px;
        }

        .moreLessButtonContainer {
            justify-content: flex-end;
            margin: 14px 75px 0 0;
        }
    }

    // iPad - landscape
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
        .timeSlotsHeader {
            margin: 0 0 0 35px;
        }

        .moreLessButtonContainer {
            justify-content: flex-end;
            margin: 14px 30px 0 0;
        }
    }
}

.daySlotsContainer {
    margin: 0 80px 15px 80px;

    .daySlots {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .moreLessButtonContainer {
        display: flex;
        justify-content: flex-end;
        margin: 14px 40px 0 0;
    }

    @media (max-width: 880px) {
        .daySlots {
            flex-wrap: wrap;
            justify-content: center;
        }
    }
}

.slotContainer {
    margin: 10px 10px !important;
    line-height: normal;

    .slot {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
        height: 50px;
        background: $white;
        border: 2px solid $gray2;
        border-radius: 9px !important;
        font-size: 22px;
        font-weight: 700;
        cursor: pointer;

        &.active {
            background: $blue4;
            color: $white;
        }
    }
}

@media (max-width: 768px) {
    .slotContainer {
        .slot {
            padding: 9px 9px;
            font-size: 12px;
        }
    }

    .daySlotsContainer {
        margin: 0 0 15px 0;
    }

    .timeSlotsContainer {
        margin: 0 0 15px 0;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .timeSlotsContainer,
    .daySlotsContainer {
        margin: 0 10px 15px 10px;
    }
    .slotContainer {
        .slot {
            padding: 10px 10px;
            font-size: 16px;
        }
    }
}

@media (min-width: 993px) and (max-width: 1200px) {
    .slotContainer {
        .slot {
            padding: 10px 10px;
            font-size: 18px;
        }
    }
}
