@import "~bootstrap/scss/bootstrap";
@import "./colors";
@import "./workflow_state.scss";
@import "./template";
@import "./workflow";
@import "./qr_overlay.scss";
@import "./forms/select_service";
@import "./fields/location_field";
@import "./fields/schedule_field";
@import "./fields/ailatech";
@import "./forms/form";
@import "./login_component";
@import "./buttons/buttons.scss";
@import "./backgroundTransitions.scss";
@import "./forms/survey_form.scss";
@import "./error_page.scss";

body {
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;
}

body.cordova {
    background: none;
    visibility: hidden;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-animation: autofill 0s forwards;
    animation: autofill 0s forwards;
}

@keyframes autofill {
    100% {
        background: transparent;
        color: inherit;
    }
}

@-webkit-keyframes autofill {
    100% {
        background: transparent;
        color: inherit;
    }
}
