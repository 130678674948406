.survey-container {
    background-color: $blue1;
    margin: 22px auto;
    border-radius: 20px;
    position: relative;
    .row {
        min-height: 539px;
        padding-bottom: 20px;
        .survey_feedback {
            min-width: 539px;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            align-content: center;
            margin-top: 40px;
            margin-left: 50px;
            margin-right: 50px;
            p {
                font-family: "Gill Sans", "Gill Sans MT", Calibri,
                    "Trebuchet MS", sans-serif;
                font-size: 1.6rem;
                margin: 10px;
                text-align: center;
            }
            .imgs {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                align-content: center;
                .first_img {
                    margin: 80px;
                    img {
                        width: 120px;
                        transition: transform 250ms;
                    }
                }
                .second_img {
                    margin: 80px;
                    img {
                        width: 120px;
                        transition: transform 250ms;
                    }
                }
                img:hover {
                    transform: scale(1.2);
                }
            }
        }
        .current_place {
            display: flex;
            justify-content: center;
            margin-top: 20px;

            .first_dot {
                border-radius: 50px;
                height: 13px;
                width: 13px;
                background-color: #5bc0de;
                margin: 20px;
                box-shadow: 2px 2px 5px #00000040, -2px -2px 5px white;
            }
            .second_dot {
                border-radius: 50px;
                height: 13px;
                width: 13px;
                background-color: #d9e2ef;
                margin: 20px;
                box-shadow: inset 2px 2px 4px #20202040,
                    inset -2px -2px 4px rgb(225, 232, 235);
            }
        }

        .survey-header {
            margin-top: 43px;
            text-align: center;
            margin-bottom: 50px;
        }
        .survey-action {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            align-content: center;
            .slider-place {
                .range-slider {
                    box-shadow: inset 8px 8px 10px #20202040,
                        inset -8px -8px 10px rgb(225, 232, 235);
                    margin: 20px;
                    margin-top: 40px;
                    padding: 0;
                    border: none;
                    overflow: hidden;
                    height: 50px;
                    line-height: 50px;
                    width: 20rem;
                    border-radius: 25px;
                    font-size: 2rem;
                    background-color: darken($blue1, 2%);
                }
            }

            .plate-one {
                width: 139px;
                height: 139px;
                border-radius: 100px;
                margin-right: 60px;
                box-shadow: 2px 2px 6px #00000040, -2px -2px 6px white;
                .bi-emoji-frown {
                    width: 139px;
                    height: 139px;
                    fill: rgba($color: #d34f4f, $alpha: 1);
                }
            }
            .plate-two {
                width: 139px;
                height: 139px;
                border-radius: 100px;
                margin-left: 60px;
                box-shadow: 2px 2px 6px #00000040, -2px -2px 6px white;
                .bi-emoji-laughing {
                    width: 139px;
                    height: 139px;
                    fill: rgba($color: #51964b, $alpha: 1);
                }
            }
        }
    }
    .button-side {
        text-align: center;
        margin-top: 70px;
        .btn-survey {
            width: 100px;
            height: 50px;
            background: #e6e6e9;
            box-shadow: 2px 2px 6px #00000040, -2px -2px 6px white;
            transition: transform 250ms;
        }
        .btn-survey:hover {
            transform: scale(1.2);
        }
    }
    .css-14pt78w-MuiSlider-rail {
        height: auto;
    }
    .css-eg0mwd-MuiSlider-thumb {
        height: 50px;
    }
    .css-1gv0vcd-MuiSlider-track {
        border-radius: unset !important;
    }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    .survey-container {
        .row {
            .survey-action {
                .plate-one {
                    width: 100px;
                    height: 100px;
                    margin-right: 20px;
                    span {
                        .bi-emoji-frown {
                            width: 100px;
                            height: 100px;
                        }
                    }
                }

                .plate-two {
                    width: 100px;
                    height: 100px;
                    margin-left: 20px;
                    .bi-emoji-laughing {
                        width: 100px;
                        height: 100px;
                    }
                }
                .slider-place {
                    .range-slider {
                        width: 16rem;
                        height: 40px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 992px) {
    .survey-container {
        .row {
            .survey-action {
                .plate-one {
                    width: 70px;
                    height: 70px;
                    margin-right: 5px;
                    span {
                        .bi-emoji-frown {
                            width: 70px;
                            height: 70px;
                        }
                    }
                }
                .plate-two {
                    width: 70px;
                    height: 70px;
                    margin-left: 5px;
                    .bi-emoji-laughing {
                        width: 70px;
                        height: 70px;
                    }
                }
                .slider-place {
                    .range-slider {
                        width: 14rem;
                        height: 35px;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 767px) {
    .survey-container {
        .row {
            min-height: 400px;

            .survey-header {
                h1 {
                    font-size: 1.3rem;
                }
            }
            .current_place {
                display: flex;
                justify-content: center;
                margin-top: 10px;

                .first_dot {
                    border-radius: 50px;
                    height: 8px;
                    width: 8px;
                    margin: 10px;
                }
                .second_dot {
                    border-radius: 50px;
                    height: 8px;
                    width: 8px;
                    margin: 10px;
                }
            }
            .survey-action {
                .plate-one {
                    width: 50px;
                    height: 50px;
                    margin-right: 5px;
                    span {
                        .bi-emoji-frown {
                            width: 50px;
                            height: 50px;
                        }
                    }
                }
                .plate-two {
                    width: 50px;
                    height: 50px;
                    .bi-emoji-laughing {
                        width: 50px;
                        height: 50px;
                    }
                }
                .slider-place {
                    .range-slider {
                        margin: 20px;
                        height: 24px;
                        width: 10rem;
                    }
                }
                .css-eg0mwd-MuiSlider-thumb {
                    width: 5px;
                }
            }
            .button-side {
                margin-top: 30px;
                .btn-survey {
                    width: 70px;
                    height: 35px;
                    font-size: 0.8rem;
                }
                .btn-survey:hover {
                    transform: scale(1.1);
                }
            }
        }
    }
}

@media screen and (max-width: 420px) {
    .survey-container {
        .row {
            min-height: 300px;
            .survey_action {
                .css-eg0mwd-MuiSlider-thumb {
                    width: 5px;
                }
            }
            .current_place {
                display: flex;
                justify-content: center;
                margin-top: 10px;

                .first_dot {
                    border-radius: 50px;
                    height: 5px;
                    width: 5px;
                    margin: 10px;
                }
                .second_dot {
                    border-radius: 50px;
                    height: 5px;
                    width: 5px;
                    margin: 10px;
                }
            }
            .survey-header {
                margin-top: 10px;
                margin-bottom: 40px;
            }
            .survey_feedback {
                min-width: 200px;
                margin-top: 10px;
                margin-left: 10px;
                margin-right: 10px;
                p {
                    font-family: "Gill Sans", "Gill Sans MT", Calibri,
                        "Trebuchet MS", sans-serif;
                    font-size: 1rem;
                    text-align: center;
                }
                .imgs {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    align-content: center;
                    .first_img {
                        margin: 0px;
                        img {
                            width: 50px;
                            transition: transform 250ms;
                        }
                    }
                    .second_img {
                        margin: 10px;
                        img {
                            width: 50px;
                            transition: transform 250ms;
                        }
                    }
                    img:hover {
                        transform: scale(1.2);
                    }
                }
            }
            .survey-action {
                .slider-place {
                    .range-slider {
                        margin: 10px;
                        margin-top: 27px;
                        padding: 0;
                        border: none;
                        overflow: hidden;
                        height: 20px;
                        line-height: 50px;
                        width: 6.3rem;
                        border-radius: 25px;
                        font-size: 2rem;
                    }
                }
            }
            .button-side {
                margin-top: 30px;
                .btn-survey {
                    font-size: 0.7rem;
                    width: 60px;
                    height: 30px;
                }
                .btn-survey:hover {
                    transform: scale(1.1);
                }
            }
        }
    }
}
@media screen and (max-width: 320px) {
    .survey-container {
        .row {
            .survey-header {
                h1 {
                    margin-bottom: 10px;
                    font-size: 1.2rem;
                    margin-top: 0px;
                }
            }
            .survey_feedback {
                min-width: 200px;
            }
            .survey-action {
                .slider-place {
                    .range-slider {
                        margin: 5px;
                        margin-top: 20px;
                        padding: 0;
                        border: none;
                        overflow: hidden;
                        height: 12px;
                        line-height: 50px;
                        width: 4.2rem;
                        border-radius: 25px;
                        font-size: 2rem;
                    }
                }
                .plate-one {
                    width: 25px;
                    height: 25px;
                    margin-right: 3px;
                    span {
                        .bi-emoji-frown {
                            width: 25px;
                            height: 25px;
                        }
                    }
                }
                .plate-two {
                    width: 25px;
                    height: 25px;
                    margin-right: 3px;

                    .bi-emoji-laughing {
                        width: 25px;
                        height: 25px;
                    }
                }
                .css-eg0mwd-MuiSlider-thumb {
                    width: 5px;
                }
            }
            .button-side {
                margin-top: 20px;
                .btn-survey {
                    width: 45px;
                    height: 25px;
                    font-size: 0.45rem;
                    text-align: center;
                }
                .btn-survey:hover {
                    transform: scale(1.1);
                }
            }
        }
    }
}
