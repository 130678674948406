$grey: rgba(0, 0, 0, 0.5);
$blue: rgba(0, 0, 255, 0.5);

@keyframes rotate-loader {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@mixin loaderDivMixin {
    border-radius: 50%;
    padding: 8px;
    border: 2px solid transparent;
    animation: rotate-loader linear 3.5s infinite;
    border-top-color: $grey;
    border-bottom-color: $blue;
}

.loader-container {
    display: flex;
    padding: 20px;
    vertical-align: middle;
    justify-content: center;

    &.loader-fixed {
        width: 100%;
        height: 100%;
        position: fixed;
        left: 0;
        top: 0;

        .loader {
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &.loader-transparency {
        background: rgba(0, 0, 0, 0.3);
    }

    .loader {
        position: relative;
        width: 150px;
        height: 150px;
        overflow: hidden;

        &.loader-box {
            background: #fff;
            border-radius: 20px;
            padding: 20px;
            width: 190px;
            height: 190px;
        }

        * {
            will-change: transform;
        }

        div {
            height: 100%;
            @include loaderDivMixin;
        }

        div:hover {
            animation-play-state: paused;
        }
    }
}
