$backgroundTransitionTime: 0.5s; //Edit also transition time in template component
$blinkColor: #f0f0f0;

.main-container {
    .app-form-background,
    .lookup-background {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        background: #f0f0f0;
        background-size: cover;
        background-position: left bottom;

        transition: $backgroundTransitionTime ease-in-out filter;
        &::after {
            content: "";
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            height: 100%;
            opacity: 0;

            background: $blinkColor !important;
            transition: $backgroundTransitionTime ease-in-out opacity;
        }
    }
}

.main-container-transition {
    .lookup-background,
    .app-form-background {
        filter: 5px;
        &::after {
            opacity: 1 !important;
        }
    }
}
