.ailatech-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    top: 0;
    left: 0;
    z-index: 1000;
    color: #fff;

    .ailatech-close {
        position: absolute;
        top: 20px;
        left: 20px;
        font-size: 20px;
        cursor: pointer;
    }

    .ailatech-instructions {
        position: absolute;
        bottom: 20px;
        right: 20px;
        font-size: 32px;
        text-align: right;
    }
}

@media only screen and (min-width: 768px) {
    .ailatech-overlay {
        .ailatech-close {
            font-size: 25px;
        }
    }
}
