.select-service {
    img {
        float: right;
        margin: 50px -15px 0 0;
        width: 100%;
        max-width: 700px;
    }

    .service-container {
        margin-left: 50px;
    }

    .MuiSelect-icon {
        right: 8px;
    }
}
.location-change {
    .MuiSelect-icon {
        right: 10px;
    }
}

.MuiTextField-root {
    padding: 0 10px !important;
    border-radius: 20px;
    background-color: $gray2;
    box-shadow: inset -2.121px -2.121px 6px 0px $gray1,
        inset 2.121px 2.121px 3px 0px rgba(189, 193, 209, 0.8);
    color: #000;

    .MuiInputBase-root {
        &::before,
        &::after {
            content: "";
            border: none;
        }
    }

    .MuiInput-underline:hover:not(.Mui-disabled):before {
        border: none;
    }
}

.MuiFormLabel-root {
    &.MuiFormLabel-root {
        left: 15px;
        top: 5px;
        font-size: 15px;
        color: $gray3;

        &.Mui-focused {
            color: $gray4;
        }

        &.MuiInputLabel-formControl {
            transform: translate(0, 10px) scale(1);
        }

        &.MuiInputLabel-shrink {
            transform: translate(0, 1.5px) scale(0.75);
        }
    }
}
.MuiSelect-select {
    margin: 0 20px !important;
    &:focus {
        background: none !important;
    }
}

.MuiSelect-icon {
    top: calc(50% - 17px) !important;
}

@media screen and (max-width: 768px) {
    .select-service {
        img {
            margin: 0;
        }
        .service-container {
            margin: 0;
        }
    }
}
