.workflow-state-container {
    position: relative;
    width: 545px;
    border-radius: 20px 0 20px 0;
    background: #eeeff3;
    float: left;
    padding: 20px 20px 5px 20px;

    .info-type {
        font-size: 20px;
        font-weight: 500;
        text-align: right;
        color: $gray4;
    }

    .info {
        font-size: 22px;
        font-weight: bold;
    }

    .location-info {
        font-size: 22px;
        p {
            margin: 0;
            &.address1 {
                font-weight: bold;
            }
            &.address2 {
                font-size: 18px;
            }
        }
    }

    .workflow-step {
        margin: 10px 0 10px 10px;
        text-align: center;
        float: left;
        color: #52284e;
        width: 60px;
        position: relative;

        div {
            display: inline-block;
            border: 3px solid #52284e;
            border-radius: 50%;
            width: 34px;
            height: 34px;
            font-weight: bold;
            line-height: 28px;
        }

        &.completed {
            color: #80982b;
            div {
                border-color: #80982b;
            }
            & + .completed::before {
                content: "";
                position: absolute;
                top: 16px;
                left: -24px;
                width: 38px;
                height: 3px;
                background: #80982b;
            }
        }

        &:first-of-type {
            margin-left: 0;
        }

        p {
            font-size: 12px;
        }
    }
}

@media screen and (max-width: 768px) {
    .workflow-state-container {
        width: 100%;

        .info-type {
            font-size: 16px;
        }

        .info {
            font-size: 16px;
        }

        .location-info {
            font-size: 16px;
            p {
                &.address2 {
                    font-size: 14px;
                }
            }
        }
        .workflow-step {
            width: 50px;
            position: relative;
            font-size: 10px;

            div {
                width: 24px;
                height: 24px;
                font-weight: bold;
                line-height: 18px;
            }

            &.completed {
                color: #80982b;
                & + .completed::before {
                    top: 10px;
                }
            }

            p {
                font-size: 12px;
            }
        }
    }
}
