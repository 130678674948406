nav {
    > div {
        padding-top: 6px;
    }

    .nav-item {
        float: right;
        margin-left: 15px;
        line-height: 43px;
        font-size: 12px;

        &.login-btn {
            background-color: $red-light;
            cursor: pointer;
            margin-right: -8px;
            height: 43px;
            font-size: 18px;
            color: $white;
            padding: 0 40px;
            letter-spacing: 2px;
        }
    }

    .search-bar {
        height: 57px;
        background-color: $blue6;
    }

    .menu {
        .menu-item {
            float: left;
            margin-top: -2px;
            padding: 0 28px;
            font-weight: 600;
            font-size: 19px;
            color: $gray4;
            line-height: 1.25;
            border-right: 2px solid $gray2;
            height: 2.5em;
        }
        padding-top: 0px;
        height: 84px;
    }

    .hamburger-menu-container {
        .hamburger-menu-icon {
            cursor: pointer;
            margin: 0 0 0 20px;
            width: 21px;

            .hamburger-menu-icon-item {
                width: 21px;
                height: 3px;
                background-color: $black;
                margin: 5px 0;
            }
        }

        .menu-item {
            border: none;
            float: none;
            font-size: 13px;
            margin: 12px 0px;
            text {
                border-bottom: 2px solid $gray2;
            }
        }

        height: auto;
        padding: 0 0 10px 0;
    }
}

.locations {
    width: 100%;
    min-height: 100vh;
    background: #fff;

    .location-box {
        padding: 20px 10px;
        border-bottom: 1px solid $gray2;
        cursor: pointer;
    }
}

// iPad - portrait
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    .hamburger-menu-container {
        position: relative;
        top: -36px;
    }
    .container-fluid {
        margin-bottom: 6px;
    }
}

// iPad - landscape
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    nav {
        .menu {
            img {
                margin-right: 40px !important;
            }
            height: 54px;
        }
    }
}

@media only screen and (max-width: 1150px) {
    nav {
        .menu {
            .menu-item {
                font-size: 15px;
            }
        }
    }
}
