.error {
    margin: 170px auto;
    position: relative;
    padding: 20px;

    .token-expired {
        text-align: center;
        display: grid;
        padding: 50px;

        .token-expired-text {
            color: rgb(114, 113, 113);
            display: block;
            h1 {
                font-size: 19rem;
                line-height: 15rem;
                color: white;
            }
            h2 {
                margin-left: 10px;
                font-size: 3rem;
            }

            .constant-message {
                font-size: 1.4rem;
                margin-top: 20px;
            }
        }
    }
}
@media screen and (max-width: 767px) {
    .error {
        margin: 230px auto;
        padding: 0px;

        .token-expired {
            .token-expired-text {
                h1 {
                    font-size: 15rem;
                    line-height: 13rem;
                    color: white;
                }
            }
        }
    }
}

@media screen and (max-width: 420px) {
    .error {
        margin: 270px auto;
        padding: 0px;

        .token-expired {
            .token-expired-text {
                h1 {
                    font-size: 10rem;
                    line-height: 11rem;
                    color: white;
                }
            }
        }
    }
}
