.workflow-container.cordova {
    display: none;
}

#qr-overlay {
    visibility: visible;
    height: 100vh;
}

#qr-close {
    visibility: visible;
    position: absolute;
    top: 25px;
    left: 25px;
    cursor: pointer;
}

#qr-close:before,
#qr-close:after {
    content: "";
    position: absolute;
    width: 60%;
    height: 8%;
    background-color: white;
    border-radius: 10%;
    top: 44%;
}

#qr-close:before {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    transform: rotate(45deg);
    left: 20%;
}

#qr-close:after {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    transform: rotate(-45deg);
    right: 20%;
}

#qr-text {
    position: absolute;
    top: 110%;
    left: 50%;
    font-size: 24px;
    transform: translate(-50%, -50%);
}

#qr-scaner {
    outline: 2000px solid rgba(0, 0, 0, 0.2);
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: linear-gradient(to right, white 6%, transparent 6%) 0 0,
        linear-gradient(to right, white 6%, transparent 6%) 0 100%,
        linear-gradient(to left, white 6%, transparent 6%) 100% 0,
        linear-gradient(to left, white 6%, transparent 6%) 100% 100%,
        linear-gradient(to bottom, white 6%, transparent 6%) 0 0,
        linear-gradient(to bottom, white 6%, transparent 6%) 100% 0,
        linear-gradient(to top, white 6%, transparent 6%) 0 100%,
        linear-gradient(to top, white 6%, transparent 6%) 100% 100%;
    background-repeat: no-repeat;
    background-size: 25% 25%;
}

@media all and (orientation: landscape) {
    #qr-close {
        width: 5vh;
        height: 5vh;
    }
    #qr-scaner {
        width: 50vh;
        height: 50vh;
    }
}

@media all and (orientation: portrait) {
    #qr-close {
        width: 5vw;
        height: 5vw;
    }
    #qr-scaner {
        width: 50vw;
        height: 50vw;
    }
}

@media only screen and (max-width: 550px) {
    #qr-text {
        font-size: 16px;
    }
}

@media only screen and (max-width: 300px) {
    #qr-text {
        font-size: 12px;
    }
}
